// App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ToastProvider from './components/Toast';
import OfflineToast from './components/OfflineToast';
import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import BuyCredits from "./components/BuyCredits";
import Checkout from "./components/Checkout";
import Homepage from "./components/Homepage";
import Landing from "./components/Landing";
import TimerPage from "./components/TimerPage";
import GuestEvents from "./components/GuestEvents";
import { useAuth } from './contexts/AuthContext';

function RootRedirect() {
  const { user } = useAuth();
  return user ? <Navigate to="/homepage" /> : <Navigate to="/landing" />;
}

function InnerApp() {
  const location = useLocation();
  return (
    <div className="app-container">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<RootRedirect />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/buy-credits" element={<BuyCredits />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/timer" element={<TimerPage key={location.key} />} />
          <Route path="/events" element={<GuestEvents />} />
          <Route path="*" element={<Navigate to="/landing" replace />} />
        </Routes>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
      <OfflineToast />
        <BrowserRouter>
          <InnerApp />
        </BrowserRouter>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;