// TimerPage.js
import React, { useState, useEffect } from 'react';
import { db } from "../firebase";
import { collection, addDoc, updateDoc, doc, onSnapshot, getDoc, setDoc, runTransaction, arrayUnion, deleteDoc, query, where } from 'firebase/firestore'; // Added deleteDoc here.
import RacerModal from './RacerModal';
import EventModal from './EventModal';
import { useAuth } from "../contexts/AuthContext";
import { auth } from '../firebase';
import { signInAnonymously } from "firebase/auth";
import { FaArrowLeft, FaClone,FaFlag, FaFlagCheckered, FaTimes, FaTrash, FaShareAlt, FaPlus } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import creditCosts from "../config/creditCosts";
import { QRCodeCanvas } from 'qrcode.react';
import { useToast } from "../components/Toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const TimerPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToast();
  const [event, setEvent] = useState(null);
  const [showRacerModal, setShowRacerModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [inviteAdminEmail, setInviteAdminEmail] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [guestRole, setGuestRole] = useState(null); // "admin" | "viewer" | null
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [editingEventName, setEditingEventName] = useState(false);
  const [eventNameEdit, setEventNameEdit] = useState("");
  const [processingRacerIds, setProcessingRacerIds] = useState([]);
const generateRandomKey = () => {
  return Math.random().toString(36).substr(2, 8); // 8-character random key
};

const markRacerProcessing = (racerId) => {
  setProcessingRacerIds((prev) => [...prev, racerId]);
};

const unmarkRacerProcessing = (racerId) => {
  setProcessingRacerIds((prev) => prev.filter((id) => id !== racerId));
};
  const getDefaultEventName = () => {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `Race ${month}/${day}/${year} ${hours}:${minutes}`;
  };
  
  // Function to update the event name:
const handleUpdateEventName = async () => {
  try {
    await updateDoc(doc(db, "events", event.id), { name: eventNameEdit });
    setEditingEventName(false);
    showToast("Event name updated.");
  } catch (error) {
    console.error("Error updating event name:", error);
    showToast("Failed to update event name.");
  }
};

// Function to duplicate the event:
const handleDuplicateEvent = async () => {
  if (!event) return;
  try {
    // Generate a new name (or you could append " (copy)" etc.)
    const newEventName = getDefaultEventName();
    // Create new guest keys for the duplicate event:
    const newGuestAdminKey = generateRandomKey();
    const newGuestViewerKey = generateRandomKey();

    // Prepare the new racers array by resetting the timing fields
    let newRacers = [];
    if (event.racers && event.racers.length > 0) {
      newRacers = event.racers.map(racer => ({
        racerId: racer.racerId,
        startTime: null,
        finishTime: null,
        duration: null,
      }));
    }

    // Build new event object based on the current event.
    // Remove any unwanted fields (like id) and update timestamps/keys.
    const newEvent = {
      ...event,
      name: newEventName,
      createdAt: Date.now(),
      userId: user.uid, // new event owner remains the current user
      admins: [user.uid],
      guestAdminKey: newGuestAdminKey,
      guestViewerKey: newGuestViewerKey,
      racers: newRacers,
    };
    delete newEvent.id; // Remove the id field if it exists

    const docRef = await addDoc(collection(db, "events"), newEvent);
    showToast("Event duplicated successfully!");
    // Automatically navigate to the new event
    navigate(`/timer?eventId=${docRef.id}`);
  } catch (error) {
    console.error("Error duplicating event:", error);
    showToast("Failed to duplicate event.");
  }
};

  const [eventNameInput, setEventNameInput] = useState(getDefaultEventName());
  const [racersMap, setRacersMap] = useState({});
  const [credits, setCredits] = useState(0);
  
  // Determine admin status: current user is admin if his UID or email is in the event admins.
  const isAdmin = guestRole === "admin" || (event?.admins?.includes(user?.uid) || event?.admins?.includes(user?.email));
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const guestAdminKeyFromUrl = params.get("guestAdminKey");
    if (guestAdminKeyFromUrl && guestAdminKeyFromUrl === event?.guestAdminKey && !user) {
      // Sign in anonymously
      signInAnonymously(auth)
        .then(() => {
          console.log("Signed in anonymously for guest admin access");
        })
        .catch((error) => {
          console.error("Anonymous sign-in error:", error);
        });
    }
  }, [location.search, event, user]);

  // Fetch user credits on mount.
  useEffect(() => {
    if (!user) return;
    const fetchCredits = async () => {
      if (!user) return;
      const userDocRef = doc(db, "users", user.uid);
      const snapshot = await getDoc(userDocRef);
      if (snapshot.exists()) {
        setCredits(snapshot.data().credits || 0);
      }
    };
    fetchCredits();
  }, [user]);

  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventId = params.get("eventId");
    console.log("TimerPage: eventId from URL:", eventId);
    if (eventId && !event) {
      const unsubscribe = onSnapshot(doc(db, "events", eventId), (docSnapshot) => {
        if (docSnapshot.exists()) {
          const loadedEvent = { id: docSnapshot.id, ...docSnapshot.data() };
          console.log("Event loaded:", loadedEvent);
          setEvent(loadedEvent);
        } else {
          console.error("Event not found");
        }
      });
      return () => unsubscribe();
    }
  }, [location.search, event]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const guestAdminKeyFromUrl = params.get("guestAdminKey");
    const guestViewerKeyFromUrl = params.get("guestViewerKey");
    console.log("Guest keys from URL:", guestAdminKeyFromUrl, guestViewerKeyFromUrl);
    if (guestAdminKeyFromUrl) {
      setGuestRole("pendingAdmin");
    } else if (guestViewerKeyFromUrl) {
      setGuestRole("pendingViewer");
    }
  }, [location.search]);
  
  useEffect(() => {
    if (event) {
      const params = new URLSearchParams(location.search);
      const guestAdminKeyFromUrl = params.get("guestAdminKey");
      const guestViewerKeyFromUrl = params.get("guestViewerKey");
      //console.log("Validating guest keys against event:", guestAdminKeyFromUrl, guestViewerKeyFromUrl, event.guestAdminKey, event.guestViewerKey);
      if (guestAdminKeyFromUrl && guestAdminKeyFromUrl === event.guestAdminKey) {
        setGuestRole("admin");
      } else if (guestViewerKeyFromUrl && guestViewerKeyFromUrl === event.guestViewerKey) {
        setGuestRole("viewer");
      } else {
        setGuestRole(null);
      }
    }
  }, [event, location.search]);
  // Fetch racers map (racerId -> racer name)
  useEffect(() => {
    const racersRef = collection(db, "timer_racers");
    const unsubscribe = onSnapshot(racersRef, (snapshot) => {
      const map = {};
      snapshot.docs.forEach((docSnap) => {
        const data = docSnap.data();
        console.log("Racer doc:", docSnap.id, data);
        map[docSnap.id] = data.name || "Unknown Racer";
      });
      setRacersMap(map);
    });
    return () => unsubscribe();
  }, []);;

  // Listen for real‑time updates on the active event.
  useEffect(() => {
    let unsubscribe;
    if (event && event.id) {
      unsubscribe = onSnapshot(doc(db, 'events', event.id), (docSnapshot) => {
        setEvent({ id: docSnapshot.id, ...docSnapshot.data() });
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [event && event.id]);

  useEffect(() => {
    // If we have an event, and a user exists, and the user is anonymous,
    // and the URL contains a valid guestAdminKey, then update the event's admins.
    const params = new URLSearchParams(location.search);
    const guestAdminKeyFromUrl = params.get("guestAdminKey");
    if (
      event &&
      guestAdminKeyFromUrl &&
      guestAdminKeyFromUrl === event.guestAdminKey &&
      user &&
      user.isAnonymous // user is anonymous
    ) {
      if (!event.admins.includes(user.uid)) {
        updateDoc(doc(db, "events", event.id), {
          admins: arrayUnion(user.uid),
          guestAdminKey: event.guestAdminKey  
        }).then(() => {
          console.log("Anonymous uid added to event admins.");
        }).catch((error) => {
          console.error("Error adding anonymous uid to event admins:", error);
        });
      }
    }
  }, [event, user, location.search]);

  const handleInviteGuestAdmin = async () => {
    if (!inviteEmail.trim()) return;
    try {
      await updateDoc(doc(db, "events", event.id), {
        admins: arrayUnion(inviteEmail.trim())
      });
      alert("Guest admin invited successfully.");
      setInviteEmail("");
      setShowInviteModal(false);
    } catch (error) {
      console.error("Error inviting guest admin:", error);
    }
  };
  // Create a new event and charge 1 credit.
  const handleCreateEvent = async (name) => {
    if (!user) return;
    if (credits < creditCosts.CREATE_EVENT) {
      alert("Not enough credits to create an event.");
      return;
    }
    try {
      // Deduct credit.
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, { credits: credits - creditCosts.CREATE_EVENT });
      setCredits(credits - creditCosts.CREATE_EVENT);

      const guestAdminKey = generateRandomKey();
      const guestViewerKey = generateRandomKey();
  
      const newEvent = {
        name,
        racers: [],
        createdAt: Date.now(),
        userId: user.uid,
        admins: [user.uid],
        guestAdminKey,  
        guestViewerKey, 
      };
      const docRef = await addDoc(collection(db, 'events'), newEvent);
      setEvent({ id: docRef.id, ...newEvent });
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  // When an event is selected from the modal.
  const handleSelectEvent = (selectedEvent) => {
    setEvent(selectedEvent);
    setShowEventModal(false);
  };

  // Add multiple racers to the event at once (prevent duplicates).
// Add multiple racers to the event at once (prevent duplicates).
const addMultipleRacersToEvent = async (selectedRacers) => {
  if (!event) return;
  const currentRacers = event.racers || [];
  // Filter out any racer already in the event
  const newRacers = selectedRacers.filter(
    (racer) => !currentRacers.some((er) => er.racerId === racer.id)
  ).map((racer) => ({
    racerId: racer.id,
    startTime: null,
    finishTime: null,
    duration: null,
  }));
  if (newRacers.length === 0) return;
  const updatedRacers = [...currentRacers, ...newRacers];
  try {
    await updateDoc(doc(db, 'events', event.id), { racers: updatedRacers });
    // Update the local event state so that the new racers show up immediately.
    setEvent((prev) => ({ ...prev, racers: updatedRacers }));
  } catch (error) {
    console.error("Error adding racers to event:", error);
  }
};

  // Remove a racer from the event.
  const removeRacerFromEvent = async (racerId) => {
    const currentRacers = event?.racers || [];
    const updatedRacers = currentRacers.filter(racer => racer.racerId !== racerId);
    try {
      await updateDoc(doc(db, 'events', event.id), { racers: updatedRacers });
      setEvent(prev => ({ ...prev, racers: updatedRacers }));
    } catch (error) {
      console.error("Error removing racer from event:", error);
    }
  };

  // Record start time using a transaction.

  // Action to record start time
  const handleStart = async (racerId) => {
    if (!event || !event.id) return;
    if (processingRacerIds.includes(racerId)) return;
    if (user && user.isAnonymous && !event.admins.includes(user.uid)) {
      alert("Guest admin access is still initializing. Please try again in a moment.");
      return;
    }
  
    // Optimistically update local state immediately.
    const optimisticEvent = { ...event };
    optimisticEvent.racers = optimisticEvent.racers.map(racer =>
      racer.racerId === racerId && !racer.startTime ? { ...racer, startTime: Date.now() } : racer
    );
    setEvent(optimisticEvent);
  
    markRacerProcessing(racerId);
  
    try {
      // Use updateDoc so that offline writes are queued if necessary.
      await updateDoc(doc(db, "events", event.id), {
        // This ensures that the write is in sync with the optimistic update.
        racers: optimisticEvent.racers,
      });
    } catch (error) {
      console.error("Error starting race:", error);
      showToast("Failed to start race. Changes may be synced later.");
      // Optionally, revert optimistic update if desired.
    } finally {
      unmarkRacerProcessing(racerId);
    }
  };


const handleFinish = async (racerId) => {
  if (!event || !event.id) return;
  if (processingRacerIds.includes(racerId)) return;
  if (user && user.isAnonymous && !event.admins.includes(user.uid)) {
    alert("Guest admin access is still initializing. Please try again in a moment.");
    return;
  }

  const finishTime = Date.now();

  // Optimistically update local state so the UI immediately reflects the finish.
  const updatedRacers = event.racers.map(racer => {
    if (racer.racerId === racerId && racer.startTime && !racer.finishTime) {
      return { ...racer, finishTime, duration: finishTime - racer.startTime };
    }
    return racer;
  });
  setEvent({ ...event, racers: updatedRacers });

  // Immediately disable the button.
  markRacerProcessing(racerId);

  try {
    // Use setDoc with merge: true so that the write is queued and applied offline.
    await setDoc(
      doc(db, "events", event.id),
      { racers: updatedRacers },
      { merge: true }
    );
    showToast("Race finished!"); 
  } catch (error) {
    console.error("Error finishing race:", error);
    showToast("Failed to finish race. Changes will sync when online.");
  } finally {
    unmarkRacerProcessing(racerId);
  }
};


  // --- NEW: Delete event functionality --- //
  const handleDeleteEvent = async () => {
    if (!event || !event.id) return;
    try {
      await deleteDoc(doc(db, "events", event.id));
      setEvent(null);
      navigate("/homepage");
    } catch (error) {
      console.error("Error deleting event", error);
    }
  };

  // Utility functions to format time.
  const formatTimeHMS = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatDuration = (ms) => {
    if (ms == null) return "";
    const totalSeconds = ms / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    const hundredths = Math.floor((ms % 1000) / 10);
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${hundredths.toString().padStart(2, '0')}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, '0')}.${hundredths.toString().padStart(2, '0')}`;
    }
  };

  const handleShareToPublic = async () => {
    try {
      // Look up the sharegroup document for this user
      const shareGroupRef = doc(db, "sharegroups", user.uid);
      const shareGroupSnap = await getDoc(shareGroupRef);
      if (!shareGroupSnap.exists()) {
        throw new Error("Sharegroup not found for the current user.");
      }
      const shareGroupData = shareGroupSnap.data();
  
      // Update the event with the sharegroup information
      await updateDoc(doc(db, "events", event.id), {
        sharedGroupId: shareGroupSnap.id, // This is the unique sharegroup id
        sharedGroupName: shareGroupData.name // Typically "public"
      });
      showToast("Event shared to your public group successfully!");
    } catch (error) {
      console.error("Error sharing event to public group:", error);
      showToast("Failed to share event. Please try again.");
    }
  };

  // Export event results as CSV.
  const exportCSV = () => {
    if (!event || !event.racers) return;
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Racer,Start Time,Finish Time,Duration\n";
    event.racers.forEach(racer => {
      const racerName = racersMap[racer.racerId] || racer.racerId;
      const start = racer.startTime ? formatTimeHMS(racer.startTime) : "";
      const finish = racer.finishTime ? formatTimeHMS(racer.finishTime) : "";
      const dur = racer.duration ? formatDuration(racer.duration) : "";
      csvContent += `"${racerName}","${start}","${finish}","${dur}"\n`;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${event.name.replace(/ /g, "_")}_results.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Compute event statistics.
  const onCourseRacers = event 
  ? event.racers
      .filter(r => r.startTime && !r.finishTime)
      .sort((a, b) => a.startTime - b.startTime)
  : [];

const inactiveRacers = event 
  ? event.racers.filter(r => !r.startTime && !r.finishTime)
  : [];

const finishedRacers = event 
  ? event.racers
      .filter(r => r.startTime && r.finishTime)
      .sort((a, b) => a.duration - b.duration)
  : [];


const totalRacers = event ? event.racers.length : 0;

  return (
    <div style={{ padding: "20px" }}>
      { !event ? (
        <div>
          {/* When no event is selected */}
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
            <button 
              onClick={() => setShowEventModal(true)} 
              className="primary-btn" 
              style={{ width: "auto" }}
            >
              Select an Event
            </button>
          </div>
          <div style={{ border: "2px dashed #ccc", borderRadius: "10px", padding: "15px", marginTop: "10px" }}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <input 
                type="text" 
                value={eventNameInput}
                onChange={(e) => setEventNameInput(e.target.value)}
                placeholder="Race Name (e.g., Race 03/15/2025 14:30)"
                style={{ padding: "5px", flex: 1 }}
              />
              <button 
                onClick={() => {
                  if (eventNameInput.trim()){
                    handleCreateEvent(eventNameInput.trim());
                    setEventNameInput(getDefaultEventName());
                  }
                }} 
                className="primary-btn" 
                style={{ width: "auto" }}
              >
                Create Event : {creditCosts.CREATE_EVENT}⚡
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {/* Event Header */}
          <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <button
    onClick={() => navigate(-1)}
    style={{
      background: "none",
      border: "none",
      cursor: "pointer",
      marginRight: "10px",
      fontSize: "1.5em",
      color: "#555",
    }}
    title="Go Back"
  >
    <FaArrowLeft />
  </button>
  <div style={{ flex: 1 }}>
    {editingEventName ? (
      <input
        type="text"
        value={eventNameEdit}
        onChange={(e) => setEventNameEdit(e.target.value)}
        onBlur={handleUpdateEventName}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleUpdateEventName();
          }
        }}
        style={{ fontSize: "1.5em", padding: "4px", width: "100%" }}
        autoFocus
      />
    ) : (
      <h2
        style={{ margin: 0, cursor: "pointer" }}
        onClick={() => {
          setEventNameEdit(event.name);
          setEditingEventName(true);
        }}
      >
        {event.name}
      </h2>
    )}
  </div>
  <div style={{ display: "flex", alignItems: "center" }}>
    {isAdmin && event.userId === user?.uid && (
      <>
        <button
          onClick={handleDuplicateEvent}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            color: "#555",
            fontSize: "1.1em",
            marginRight: "10px",
          }}
          title="Duplicate Event"
        >
          <FaClone />
        </button>
        <button
          onClick={handleDeleteEvent}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            color: "#555",
            fontSize: "1.1em",
          }}
          title="Delete Event"
        >
          <FaTrash />
        </button>
      </>
    )}
  </div>
</div>

          {/* Event Statistics */}
          <div style={{ marginBottom: "15px", textAlign: "center" }}>
            <span>Finished: {finishedRacers.length}</span> |{" "}
            <span>On Course: {onCourseRacers.length}</span> |{" "}
            <span>Total: {totalRacers}</span>
          </div>


          {/* On Course Racers List */}
          <div style={{ marginTop: "20px" }}>
            <h3>🏃 Racers On Course</h3>
            {onCourseRacers.length > 0 ? (
              <TransitionGroup component="ul" style={{ listStyle: "none", padding: 0, margin: 0 }}>
                {onCourseRacers.map(racer => (
                  <CSSTransition key={racer.racerId} timeout={300} classNames="fade">
                  <li key={racer.racerId} style={{ display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "10px", marginBottom: "5px", borderRadius: "5px" }}>
                    <div style={{ flex: "1" }}>
                      <strong>{racersMap[racer.racerId] || racer.racerId}</strong>
                      <div>
                        {racer.startTime && <span
                        style={{
                          background: "none", 
                          border: "none", 
                          cursor: "pointer", 
                          color: "#555", 
                          fontSize: ".9em",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px"
                        }}          
    ><FaFlag /> {formatTimeHMS(racer.startTime)}</span>}
                        {racer.finishTime && <span> | <FaFlagCheckered /> {formatTimeHMS(racer.finishTime)}</span>}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <button 
                        onClick={() => handleFinish(racer.racerId)}
                        style={{ padding: "10px 15px", borderRadius: "8px", backgroundColor: "#FE6C62", color: "#fff", border: "none", cursor: "pointer" }}
                        disabled={!isAdmin || processingRacerIds.includes(racer.racerId)}
                      >
                        <FaFlagCheckered /> Finish
                      </button>
                    </div>
                  </li>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            ) : <p>No racers on course.</p>}
          </div>

          {/* Inactive Racers List with Add Racer Plus Icon */}
          <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3>🚦 Inactive Racers</h3>
            {isAdmin && (
              <button 
                onClick={() => setShowRacerModal(true)}
                style={{ 
                  background: "#666", 
                  borderRadius: "10px", 
                  padding: "10px",marginRight: "14px", border: "none", cursor: "pointer", fontSize: ".9em", color: "white" }}
                title="Add Racer"
              >
                <FaPlus /> racers
              </button>
            )}
          </div>
          {inactiveRacers.length > 0 ? (
            <TransitionGroup component="ul" style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {inactiveRacers.map(racer => (
                <CSSTransition key={racer.racerId} timeout={300} classNames="fade">
                <li key={racer.racerId} style={{ display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "10px", marginBottom: "5px", borderRadius: "5px" }}>
                  <div style={{ flex: "1" }}>
                  <button 
                      onClick={() => removeRacerFromEvent(racer.racerId)}
                      style={{ background: "none", border: "none", cursor: "pointer", color: "#555", fontSize: "1.2em" }}
                      disabled={!isAdmin}
                    >
                      <FaTimes />
                    </button>
                    <strong>{racersMap[racer.racerId] || racer.racerId}</strong>
                    <div>
                      {racer.startTime && <span><FaFlag />: {formatTimeHMS(racer.startTime)}</span>}
                      {racer.finishTime && <span> | <FaFlagCheckered />: {formatTimeHMS(racer.finishTime)}</span>}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <button 
                      onClick={() => handleStart(racer.racerId)}
                      style={{ padding: "10px 15px", borderRadius: "8px", backgroundColor: "#13A1A8", color: "#fff", border: "none", marginRight: "5px", cursor: "pointer" }}
                      disabled={!isAdmin || processingRacerIds.includes(racer.racerId)}
                    >
                      <FaFlag /> Start
                    </button>
                  </div>
                </li>
                </CSSTransition>
              ))}
              </TransitionGroup>
          ) : <p>No inactive racers.</p>}

          {/* Leaderboard List */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
            <h3>🏆 Leaderboard</h3>
            <div style={{ position: "relative" }}>
              <button 
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                style={{ background: "none", border: "none", cursor: "pointer", color: "#555",fontSize: "1.1em" }}
                title="Share"
              >
                <FaShareAlt />
              </button>
              {showShareDropdown && (
                <div style={{
                  position: "absolute",
                  right: 0,
                  top: "100%",
                  background: "#fff",
                  border: "1px solid #ccc",
                  width: "250px",
                  borderRadius: "4px",
                  boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                  zIndex: 100,
                }}>
                  <button 
                    onClick={exportCSV}
                    style={{ padding: "8px", width: "100%", background: "none", border: "none", textAlign: "left", cursor: "pointer" }}
                  >
                    Export CSV
                  </button>
                  <button 
                    onClick={() => {
                      // For example, copy viewer link to clipboard or navigate.
                      navigator.clipboard.writeText(`${window.location.origin}/timer?eventId=${event.id}&guestViewerKey=${event.guestViewerKey}`);
                      alert("Viewer link copied to clipboard!");
                    }}
                    style={{ padding: "8px", width: "100%", background: "none", border: "none", textAlign: "left", cursor: "pointer" }}
                  >
                    Viewer Link
                  </button>
                  <button 
                    onClick={handleShareToPublic}
                    style={{ padding: "8px", width: "100%", background: "none", border: "none", textAlign: "left", cursor: "pointer" }}
                  >
                    Share to Public URL
                  </button>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: "20px", padding: "0px"}}>
          {finishedRacers.length > 0 ? (
            <TransitionGroup component="ul" style={{ listStyle: "none", padding: 0, margin: 0 }}>
    {finishedRacers.map((racer, index) => (
      <CSSTransition key={racer.racerId} timeout={300} classNames="fade">
      <li
  key={racer.racerId}
  style={{ 
    display: "flex", 
    alignItems: "stretch",  // stretch children to full height
    border: "1px solid #ccc", 
    padding: "0px", 
    marginBottom: "5px",
    borderRadius: "5px",
  }}
>
  {/* Left Section: Rank Cartridge */}
  <div
    style={{
      backgroundColor: "#333",  // dark grey
      color: "#fff",            // white text
      width: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      borderRadius: "4px 0 0 4px",
      fontWeight: "bold",
      fontSize: "0.9em",
      margin: 0,       // remove extra margins if any
      padding: "5px 0",// adjust vertical padding if needed
    }}
  >
    #{index + 1}
  </div>
  
  {/* Right Section remains unchanged */}
  <div style={{ flex: 1 }}>
    {/* First Row: Racer's Name and Total Time */}
    <div 
      style={{ 
        display: "flex", 
        margin: "8px",
        justifyContent: "space-between", 
        marginBottom: "4px",
        fontWeight: "bold",
        alignItems: "center",
      }}
    >
      <span>{racersMap[racer.racerId] || racer.racerId}</span>
      <span>{formatDuration(racer.duration)}</span>
    </div>
    {/* Second Row: Remove Action and Start/Finish Times */}
    <div 
      style={{ 
        display: "flex",
        margin: "8px", 
        alignItems: "center", 
        opacity: 0.75,
        fontSize: "0.9em",
        padding: 0,
      }}
    >
    <button 
  onClick={() => removeRacerFromEvent(racer.racerId)}
  style={{ 
    background: "none", 
    border: "none", 
    cursor: "pointer", 
    color: "#555", 
    fontSize: ".9em",
    display: "flex",
    alignItems: "center",
    gap: "4px"  // reduces space between the cross and the text
  }}
  disabled={!isAdmin}
  title="Remove racer"
>
  <FaTimes />
  <span>Remove</span>
</button>
      <span style={{ 
        background: "none", 
    border: "none", 
    cursor: "pointer", 
    color: "#555", 
    fontSize: ".9em",
    display: "flex",
    alignItems: "center",
    gap: "0px",  
marginRight: "6px"
        }}>
        <FaFlag style={{ marginRight: "4px" }}/>
        {formatTimeHMS(racer.startTime)}
      </span>
      <span style={{
        background: "none", 
    border: "none", 
    cursor: "pointer", 
    color: "#555", 
    fontSize: ".9em",
    display: "flex",
    alignItems: "center",
    gap: "0px"  // reduces space between the cross and the text
      }}>
        <FaFlagCheckered style={{ marginRight: "4px" }}/>
        {formatTimeHMS(racer.finishTime)}
      </span>
    </div>
  </div>
</li>
</CSSTransition>
    ))}
    </TransitionGroup>
) : (
  <p>No finished racers.</p>
)}     </div>

          {/* Action Buttons */}
          <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" }}>
            <button
              disabled={!isAdmin}
              onClick={() => {
                setEvent(null);
                navigate("/timer", { replace: true });
                setShowEventModal(true);
              }}
              className="primary-btn"
              style={{ width: "auto", backgroundColor: "#aaa", padding: "10px 15px", borderRadius: "8px", color: "#fff", border: "none", cursor: "pointer" }}
            >
              Change Event
            </button>
            {isAdmin && (
              <button
                onClick={() => setShowInviteModal(true)}
                className="primary-btn"
                style={{ width: "auto", backgroundColor: "#13A1A8", padding: "10px 15px", borderRadius: "8px", color: "#fff", border: "none", cursor: "pointer" }}
              >
                Invite Admin
              </button>
            )}
          </div>

          {/* Invite Modal */}
          {showInviteModal && (
            <div
              className="modal-overlay"
              onClick={() => setShowInviteModal(false)}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
                style={{
                  background: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  width: "90%",
                  maxWidth: "400px",
                  textAlign: "center",
                }}
              >
                <h3>Invite Guest Admin</h3>
                <p>
                  You can invite a guest admin by 
                  <br/>- sharing this <a href={`${window.location.origin}/timer?eventId=${event.id}&guestAdminKey=${event.guestAdminKey}`}>link</a>
                  <br/>- or sharing the QR code below.
                </p>
                {event && (
                  <>
                    <QRCodeCanvas
                      value={`${window.location.origin}/timer?eventId=${event.id}&guestAdminKey=${event.guestAdminKey}`}
                      size={128}
                    />
              
                  </>
                )}
                <p>- or enter your guest admin email:</p>
                <input
                  type="email"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  placeholder="Enter guest admin email"
                  style={{ padding: "8px", width: "80%", marginBottom: "10px" }}
                /><br/><br/>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <button
                    onClick={() => setShowInviteModal(false)}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#ccc",
                      border: "none",
                      color: "#333",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleInviteGuestAdmin}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#13A1A8",
                      border: "none",
                      color: "#fff",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                  >
                    Invite by Email
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Racer Modal */}
          {showRacerModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <RacerModal 
                  eventOwnerId={event.userId}
                  onClose={() => setShowRacerModal(false)}
                  onSelectRacers={(selectedRacers) => {
                    addMultipleRacersToEvent(selectedRacers);
                    setShowRacerModal(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {showEventModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <EventModal 
              onClose={() => setShowEventModal(false)}
              onSelectEvent={handleSelectEvent}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimerPage;